export const adminSearchUserQuery = "adminSearchUserQuery";
export const adminUserDetailsQuery = "adminUserDetailsQuery";
export const availableSubscriptions = "availableSubscriptions";
export const adminOrganisationMembers = "adminOrganisationMembers";
export const adminUserApiKeys = "adminUserApiKeys";
export const adminUserApiKey = "adminUserApiKey";
export const adminUserMemberships = "adminUserMemberships";
export const networkAccessibility = "networkAccessibility";
export const apiKeys = "apiKeys";
export const apiProducts = "apiProducts";
export const subscription = "subscription";
export const billingUsage = "billingUsage";

export const paymentMethod = "paymentMethod";
export const dailyUsage = "dailyUsage";
export const datasetsSearchQuery = "datasetsSearchQuery";
export const customDatasetsSearchQuery = "customDatasetsSearchQuery";
export const customDatasetDefinitions = "datasetsCustomDefinitions";
export const releasesDatasetsQuery = "releasesDatasetsQuery";
export const DatumValuesSearchQuery = "DatumValuesSearchQuery";

export const usageGraph = "usageGraph";
export const organisation = "organisation";
export const overage = "overage";
export const datasetsAccessibility = "datasetsAccessibility";

export const userInvitations = "userInvitations";

export const customDatasetsDefinitions = "customDatasetsDefinitions";
export const definitionsKey = "datasetDefinitions";
export const catalogueEntryKey = "catalogueEntry";
export const catalogueListKey = "catalogueList";

export const userLicences = "userLicences";
export const intercomIdentityHash = "intercomIdentityHash";

export const addressById = "addressById";
export const jwt = "jwt";
export const mapsIndexJson = "mapsIndexJson";

export const batchesKey = "batches";
export const batchesDefinitionsKey = "batchesDefinitions";
export const batchesAdditionalPropertiesKey = "batchesAdditionalProperties"; 
export const batchesQuoteJob = "batchesQuoteJob";
export const batchesQuotedBatchConfig = "batchesQuotedBatchConfig";
export const batchesStartJob = "batchesStartJob";
export const batchesStopJob = "batchesStopJob";

export const demosKey = "demos";

export const clipsKey = "clips";
export const adminClipsKey = "adminClips";
export const clipSummaryKey = "clipSummary";
export const clipInvoiceKey = "clipInvoice";
export const clipConfigKey = "clipConfig";
export const batchesSampleResponse = "batchesSampleResponse";

export const landParcelsPropertiesKey = "landParcelsProperties";
export const landParcelsCadastresKey = "landParcelsCadastres";
export const buildingsKey = "buildings";
export const authenticatedUser = "authenticatedUser";
export const preferredMfa = "preferredMfa";