import { Auth } from "aws-amplify";
import { IdentityState } from "contexts/identity-context";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AccountDetails } from "pages/geoscape-admin/account/account-details/utils";
import {
  ClipConfiguration,
  ClipInvoice,
  QuoteSummary,
} from "pages/geoscape-data/explorer/clip/models";
import { TileJSON } from "pages/geoscape-data/explorer/layers/models";
import { makeApiRequestPostToUrl, makeApiRequestToUrl } from "utils/api-client";
import {
  AdminSearchUser,
  BillingUsage,
  CustomDatasetDefinitions,
  CustomDatasetFilterType,
  CustomDatasetList,
  Dataset,
  DatasetDefinitions,
  DatasetFiltersType,
  DatasetList,
  Organisation,
  Overage,
  PaymentMethod,
  PortalPlan,
  Release,
  Subscription,
  UserMembership,
} from "./models";

interface ReactQueryQueryError {
  message: string;
  stack: string;
}

async function fetchOrganisation(
  identityState: IdentityState
): Promise<Organisation> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/organisation",
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch organisation: ${err}`);
  }
}

async function fetchPaymentMethod(
  isUserPage: boolean,
  identityState: IdentityState
): Promise<PaymentMethod | null> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/paymentMethod",
      "GET",
      isUserPage ? false : identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    const paymentMethods: PaymentMethod[] = responseData.filter(
      (paymentMethod: PaymentMethod) => paymentMethod.active
    );
    if (paymentMethods.length !== 0) {
      return paymentMethods[0];
    } else {
      return null;
    }
  } catch (err) {
    throw new Error(`Unable to fetch payment method: ${err}`);
  }
}

async function fetchCustomDatasets(
  queryFields: CustomDatasetFilterType,
  identityState: IdentityState
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom?${searchParams.toString()}`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all custom datasets: ${err}`);
  }
}

async function fetchCustomDatasetsAdmin(
  queryFields: CustomDatasetFilterType,
  apigeeDeveloperId: string,
  identityState: IdentityState
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/developer/${apigeeDeveloperId}?${searchParams.toString()}`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch all custom datasets as admin for account '${apigeeDeveloperId}' : ${err}`
    );
  }
}

async function fetchDatasets(
  queryFields: DatasetFiltersType,
  identityState: IdentityState
): Promise<DatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets?${searchParams.toString()}`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all datasets: ${err}`);
  }
}

async function fetchAdminDatasets(
  queryFields: DatasetFiltersType,
  identityState: IdentityState
): Promise<DatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/search?${searchParams.toString()}`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all datasets as admin: ${err}`);
  }
}

async function fetchAdminDatasetsLink(
  datasetId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/download/${datasetId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download dataset as admin for dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchDatasetsLink(
  datasetId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/${datasetId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(`Unable to download dataset '${datasetId}' : ${err}`);
  }
}

async function fetchCustomDatasetsLink(
  datasetId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/${datasetId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download custom dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchAdminCustomDatasetsLink(
  datasetId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/${datasetId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData.datasets[0].downloadLink;
  } catch (err) {
    throw new Error(
      `Unable to download custom dataset '${datasetId}' : ${err}`
    );
  }
}

async function fetchAdminCustomDatasetsSearch(
  queryFields: CustomDatasetFilterType,
  identityState: IdentityState
): Promise<CustomDatasetList> {
  try {
    const queryFields_update: any = { ...queryFields };

    queryFields_update.release = queryFields_update.latestRelease
      ? "latest"
      : queryFields_update.release;
    delete queryFields_update.latestRelease;

    queryFields_update.product = queryFields_update.name
      ? queryFields_update.name.map((name: string) => name.toUpperCase())
      : "";
    delete queryFields_update.name;

    let searchParams = new URLSearchParams();
    searchParams.set("limit", "100");
    Object.entries(queryFields_update).forEach(([key, value]: any) =>
      value.toString().length ? searchParams.set(key, value) : ""
    );

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/search?${searchParams.toString()}`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all custom datasets as admin : ${err}`);
  }
}

async function fetchSubscription(
  isUserPage: boolean,
  identityState: IdentityState
): Promise<Subscription> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/subscriptions",
      "GET",
      isUserPage ? false : identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch subscription: ${err}`);
  }
}

async function fetchOverage(
  isUserPage: boolean,
  identityState: IdentityState
): Promise<Overage> {
  let overage: Overage = {
    enabled: false,
    dollarsSpent: 0,
    dollarsLimit: 0,
    creditsSpent: 0,
    creditsLimit: 0,
    overageRate: 0,
  };
  // Get the overage limit in dollars
  try {
    let overageResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/overage",
      "GET",
      isUserPage ? false : identityState
    );
    const overageResponseData = await overageResponse.json();

    if (overageResponse.status !== 200) {
      throw new Error(overageResponseData.messages.join(". "));
    }

    overage.enabled = overageResponseData.enabled;
    overage.dollarsLimit = overageResponseData.limitDollars;
    overage.creditsLimit = overageResponseData.limitCredits;
    overage.overageRate = overageResponseData.overageRate;
  } catch (err) {
    throw new Error(`Unable to fetch overage (overage): ${err}`);
  }

  try {
    let billingUsageResponse = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/billingUsage",
      "GET",
      isUserPage ? false : identityState
    );
    const billingUsageResponseData = await billingUsageResponse.json();

    if (billingUsageResponse.status !== 200) {
      throw new Error(billingUsageResponseData.messages.join(". "));
    }

    overage.creditsSpent = billingUsageResponseData.overageCredits;
    overage.dollarsSpent = billingUsageResponseData.overageDollars;
  } catch (err) {
    throw new Error(`Unable to fetch overage (billing usage): ${err}`);
  }
  return overage;
}

async function fetchUserInvitations(): Promise<UserMembership[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/user/membership?byEmail=true",
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user invitations: ${err}`);
  }
}

async function fetchBillingUsage(
  isUserPage: boolean,
  identityState: IdentityState
): Promise<BillingUsage> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/billingUsage",
      "GET",
      isUserPage ? false : identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch billing usage: ${err}`);
  }
}

async function fetchUsage(
  isUserPage: boolean,
  identityState: IdentityState,
  startTime: number,
  endTime: number,
  overrideIntervalTime?: string
) {
  try {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    // Get the timezone string from the user's device
    const userTimezone = dayjs.tz.guess();

    // Construct the query parameters
    let queryParams = `offset=${userTimezone}&startTime=${startTime}&endTime=${endTime}`;

    if (overrideIntervalTime !== undefined) {
      queryParams += `&overrideIntervalTime=${overrideIntervalTime}`;
    }

    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/usage?${queryParams}`,
      "GET",
      isUserPage ? false : identityState
    );

    const responseData = await response.json(); // add type: Usage

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch usage data: ${err}`);
  }
}

async function fetchApiKeys(isUserPage: boolean, identityState: IdentityState) {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/apiKeys`,
      "GET",
      isUserPage ? false : identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch API keys: ${err}`);
  }
}

async function fetchDataReleases(
  identityState: IdentityState
): Promise<Release[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/release`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch data releases: ${err}`);
  }
}

async function fetchReleaseDatasets(
  releaseId: string,
  identityState: IdentityState
): Promise<Dataset[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/release/${releaseId}/datasets`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch data release '${releaseId}' : ${err}`);
  }
}

async function fetchDefinitions(
  identityState: IdentityState
): Promise<DatasetDefinitions> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/definitions`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch dataset definitions: ${err}`);
  }
}

async function fetchCustomDefinitions(
  identityState: IdentityState
): Promise<CustomDatasetDefinitions> {
  let products;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/definitions`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch custom dataset definitions: ${err}`);
  }
}

async function fetchAdminCustomDefinitions(
  identityState: IdentityState
): Promise<CustomDatasetDefinitions> {
  let products;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/datasets/custom/definitions`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch admin custom dataset definitions: ${err}`);
  }
}

async function fetchApiProducts(identityState: IdentityState) {
  let apiProducts;

  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/products`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch API products data: ${err}`);
  }
}

async function fetchOrganisationInvitations(
  organisationId: string,
  identityState: IdentityState
) {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/organisation/membership?orgId=${
        organisationId ? encodeURIComponent(organisationId) : ""
      }`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch organisation invitations for organisation '${organisationId}' : ${err}`
    );
  }
}

// Query for fetching the Users in the admin portal search
async function fetchUsersAdminSearch(
  query: string,
  orgFilter: boolean,
  identityState: IdentityState
): Promise<AdminSearchUser[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/users/search?query=${encodeURIComponent(
        query
      )}&orgFilter=${orgFilter}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch users with query '${query}' and isOrg '${orgFilter}' : ${err}`
    );
  }
}

async function fetchDatasetsSearch(
  query: string,
  page: number,
  perPage: number,
  identityState: IdentityState
): Promise<AdminSearchUser[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets?name=${query}&page=${page}&perpage=${perPage}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch datasets with query '${query}' : ${err}`);
  }
}

// Query for fetching the Users Details in the admin portal search
async function fetchUserDetailsAdmin(
  customer_id: string,
  identityState: IdentityState
): Promise<AccountDetails> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/users/${customer_id}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch user details as admin for account '${customer_id}' : ${err}`
    );
  }
}

async function checkAdminNetworkAccessibility(
  identityState: IdentityState
): Promise<boolean> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/admin/network",
      "GET",
      identityState
    );

    return response.status === 200;
  } catch (err) {
    return false;
  }
}

async function checkDatasetsAccessibility(
  identityState: IdentityState
): Promise<boolean> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      "/datasets/beta",
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      return false;
    }

    return responseData;
  } catch (err) {
    return false;
  }
}

async function fetchAdminOrgMembers(
  orgId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/members?orgApigeeDeveloperId=${encodeURIComponent(
        orgId
      )}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch organisation members as admin for organisation '${orgId}' : ${err}`
    );
  }
}

async function fetchAdminUserMemberships(
  apigeeDeveloperId: string,
  identityState: IdentityState
): Promise<UserMembership[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/organisation/user/membership?apigeeDeveloperId=${encodeURIComponent(
        apigeeDeveloperId
      )}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch user memberships as admin for the user '${apigeeDeveloperId}' : ${err}`
    );
  }
}

async function fetchAvailableSubscriptions(
  identityState: IdentityState
): Promise<PortalPlan[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/subscriptions/all`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all available subscriptions: ${err}`);
  }
}

async function fetchAdminUserApiKeys(
  accountId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys?customerId=${accountId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch api keys as admin for user '${accountId}' : ${err}`
    );
  }
}

async function fetchAdminUserApiKey(
  apiKeyName: string,
  accountId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/apiKeys/${apiKeyName}?customerId=${accountId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch api key for user as admin for user '${accountId}' and api key '${apiKeyName}': ${err}`
    );
  }
}

async function fetchCustomDatasetsDefinitions(
  developerId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/custom/definitions?developerId=${developerId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch custom dataset definitions for user '${developerId}' : ${err}`
    );
  }
}

async function fetchCatalogueEntry(
  productId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/dataCatalogues/${productId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch data catalogue entry for product '${productId}' : ${err}`
    );
  }
}

async function fetchCatalogueList(
  identityState: IdentityState,
  query: string
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/dataCatalogues?query=${query}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }
    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch all data catalogue products for query '${query}' : ${err}`
    );
  }
}

interface ContractInfo {
  contract_start: string;
  contract_end: string;
  product_name: string;
  regions: string[];
}
interface UserLicences {
  dataset_products: ContractInfo[];
}

async function fetchUserLicences(
  identityState: IdentityState
): Promise<UserLicences> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/datasets/licences`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch user licences: ${err}`);
  }
}

async function fetchIntercomIdentityHash(
  identityState: IdentityState
): Promise<string> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/intercom/user_hash`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch intercom user hash: ${err}`);
  }
}

async function fetchAuthJWT() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

async function fetchMapsIndex(authorisation: string): Promise<TileJSON[]> {
  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_BASE_URL
      }/v1/explorer/index.json?key=${authorisation}`
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch maps index: ${err}`);
  }
}

async function fetchBatches(identityState: IdentityState): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/config`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all batch jobs: ${err}`);
  }
}

// Fetch a single batch config
async function fetchBatchConfig(
  batchId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/config/${batchId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch job '${batchId}' : ${err}`);
  }
}

async function fetchBatchesDefinitions(
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/definitions`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch definitions: ${err}`);
  }
}

async function fetchBatchesAdditionalProperties(
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/additionalProperties`,
      "GET",
      identityState
    );

    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch batch additional properties: ${err}`);
  }
}

async function fetchDemosData(identityState: IdentityState): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/demos`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all demos: ${err}`);
  }
}
async function fetchAddressById(addressId: string): Promise<string> {
  try {
    const response = await fetch(
      `https://api.psma.com.au/v1/predictive-demo/address/${addressId}`
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch address by id '${addressId}' : ${err}`);
  }
}

async function fetchClips(
  identityState: IdentityState
): Promise<ClipConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/config`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all clips: ${err}`);
  }
}

async function fetchAdminClips(): Promise<ClipConfiguration[]> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/config`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch all clips for admin: ${err}`);
  }
}

// Fetch a single clip config
async function fetchClipConfig(
  clipId: string,
  identityState: IdentityState
): Promise<ClipConfiguration> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/config/${clipId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch clip config '${clipId}' : ${err}`);
  }
}

const fetchBatchSample = async (
  batchId: string,
  body: any,
  jobType: string,
  identityState: IdentityState
) => {
  try {
    const response = await makeApiRequestPostToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/batches/sample?jobType=${jobType}`,
      "POST",
      body,
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch sample results for batch '${batchId}' : ${err}`
    );
  }
};
// Fetch a single clip summary
async function fetchClipSummary(
  clipId: string,
  identityState: IdentityState
): Promise<QuoteSummary> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/clip/summary/${clipId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(`Unable to fetch clip summary for '${clipId}' : ${err}`);
  }
}

async function fetchAdminClipSummary(clipId: string): Promise<QuoteSummary> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/summary/${clipId}`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch clip summary for admin '${clipId}' : ${err}`
    );
  }
}

async function fetchAdminClipInvoice(clipId: string): Promise<ClipInvoice> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/admin/clip/${clipId}/invoice`,
      "GET"
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch clip invoice for admin '${clipId}' : ${err}`
    );
  }
}

async function fetchLandParcelsProperties(
  addressId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/landParcels/properties/findByIdentifier?addressId=${addressId}`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch land parcels properties for address id ${addressId}: ${err}`
    );
  }
}

async function fetchLandParcelsCadastres(
  addressId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/landParcels/cadastres/findByIdentifier?addressId=${addressId}&additionalProperties=planningBundle`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch land parcels cadastres for address id ${addressId}: ${err}`
    );
  }
}

async function fetchBuildingsByAddressId(
  addressId: string,
  identityState: IdentityState
): Promise<any> {
  try {
    const response = await makeApiRequestToUrl(
      `${import.meta.env.VITE_DELIVERY_API_URL}`,
      `/buildings?addressId=${addressId}&include=all`,
      "GET",
      identityState
    );
    const responseData = await response.json();

    if (response.status !== 200) {
      throw new Error(responseData.messages.join(". "));
    }

    return responseData;
  } catch (err) {
    throw new Error(
      `Unable to fetch buildings for address id ${addressId}: ${err}`
    );
  }
}

export {
  checkAdminNetworkAccessibility,
  checkDatasetsAccessibility,
  fetchAddressById,
  fetchAdminClipInvoice,
  fetchAdminClips,
  fetchAdminClipSummary,
  fetchAdminCustomDatasetsLink,
  fetchAdminCustomDatasetsSearch,
  fetchAdminCustomDefinitions,
  fetchAdminDatasets,
  fetchAdminDatasetsLink,
  fetchAdminOrgMembers,
  fetchAdminUserApiKey,
  fetchAdminUserApiKeys,
  fetchAdminUserMemberships,
  fetchApiKeys,
  fetchApiProducts,
  fetchAuthJWT,
  fetchAvailableSubscriptions,
  fetchBatchConfig,
  fetchBatches,
  fetchBatchesAdditionalProperties,
  fetchBatchesDefinitions,
  fetchBatchSample,
  fetchBillingUsage,
  fetchBuildingsByAddressId,
  fetchCatalogueEntry,
  fetchCatalogueList,
  fetchClipConfig,
  fetchClips,
  fetchClipSummary,
  fetchCustomDatasets,
  fetchCustomDatasetsAdmin,
  fetchCustomDatasetsDefinitions,
  fetchCustomDatasetsLink,
  fetchCustomDefinitions,
  fetchDataReleases,
  fetchDatasets,
  fetchDatasetsLink,
  fetchDefinitions,
  fetchDemosData,
  fetchIntercomIdentityHash,
  fetchLandParcelsCadastres,
  fetchLandParcelsProperties,
  fetchMapsIndex,
  fetchOrganisation,
  fetchOrganisationInvitations,
  fetchOverage,
  fetchPaymentMethod,
  fetchReleaseDatasets,
  fetchSubscription,
  fetchUsage,
  fetchUserDetailsAdmin,
  fetchUserInvitations,
  fetchUserLicences,
  fetchUsersAdminSearch,
};
export type { ReactQueryQueryError };

