import { ApiKeysPage, CreateKey } from "components/api-keys";
import { EditApiKey } from "components/api-keys/edit-api-key";
import { Dashboard } from "components/dashboard/dashboard";
import { Organisations } from "components/organisations/organisations";
import { BillingDetails as OrgBillingDetails } from "components/settings/organization/billing-details/billing-details";
import { GeneralSettings } from "components/settings/organization/general-settings/general-settings";
import { OrganisationMembers } from "components/settings/organization/members/members";
import { Overage as OrgOnDemand } from "components/settings/organization/overage/overage";
import { Subscription as OrgSubscription } from "components/settings/organization/subscription/subscription";
import { BillingDetails as UserBillingDetails } from "components/settings/user/billing-details/billing-details";
import { LegalPage } from "components/settings/user/legal/legal";
import { Overage as UserOnDemand } from "components/settings/user/overage/overage";
import { UserInvitations } from "components/settings/user/pending-invitations/user-invitations";
import { Subscription as UserSubscription } from "components/settings/user/subscription/subscription";
import { TestError } from "components/test";
import { Usage } from "components/usage/usage-page";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { AdminAccountSubscription } from "pages/geoscape-admin/account/account-subscription/account-subscription";
import { AdminApiKeys } from "pages/geoscape-admin/account/api-keys/api-keys";
import { AdminCreateApiKey } from "pages/geoscape-admin/account/api-keys/create-api-keys";
import { AdminEditApiKey } from "pages/geoscape-admin/account/api-keys/edit-api-keys";
import { AdminMembers } from "pages/geoscape-admin/account/members/members";
import { CreateCustomDatasets } from "pages/geoscape-admin/custom-datasets/create-custom-datasets";
import { ViewCustomDatasets } from "pages/geoscape-admin/custom-datasets/view-custom-datasets";
import { AdminDashboardPage } from "pages/geoscape-admin/dashboard/dashboard-page";

import { OrganisationManageSubscription } from "components/settings/organization/subscription/organisation-manage-subscription";
import { UserManageSubscription } from "components/settings/user/subscription/user-manage-subscription";
import { MangeReleasePage } from "pages/geoscape-admin/manage-release/manage-release-page";
import { NewRelease } from "pages/geoscape-admin/manage-release/new-release/new-release";
import { ViewAllDatasets } from "pages/geoscape-admin/manage-release/view-all/view-all-datasets";
import { CataloguePage } from "pages/geoscape-data/catalogue/catalogue-page";
import { CustomDownloadPage } from "pages/geoscape-data/custom-download/custom-page";
import { DataPage } from "pages/geoscape-data/dashboard/dashboard-page";
import { DownloadPage } from "pages/geoscape-data/download/download-page";

import { ErrorBoundary } from "@sentry/react";
import { UserSecurity } from "components/settings/user/security/user-security";
import { ErrorFallback } from "components/shared/error-fallback";
import { AdminUsageAccount } from "pages/geoscape-admin/account/account-usage/account-usage-page";
import { AdminUserMemberships } from "pages/geoscape-admin/account/memberships/memberships";
import { AdminCustomDatasetsSearch } from "pages/geoscape-admin/custom-datasets/admin-custom-datasets-search";
import { AdminDatasets } from "pages/geoscape-admin/datasets/admin-datasets-page";
import { PropertyInsights } from "pages/geoscape-admin/property-insights/property-insights";
import { AdminClipReporting } from "pages/geoscape-admin/reporting/clip/admin-clip-reporting";
import { BatchPage } from "pages/geoscape-batch/batch-page";
import { CreateBatchPage } from "pages/geoscape-batch/create-batch-steps/create-batch-page";
import { CatalogueEntry } from "pages/geoscape-data/catalogue/catalogue-entry/catalouge-entry";
import { ExplorerPage } from "pages/geoscape-data/explorer/explorer-page";
import { TailorPage } from "pages/geoscape-data/tailor/tailor-page";
import { DemosPage } from "pages/geoscape-demos/demos-page";
import { DemoBasePage } from "pages/geoscape-demos/demos/demo-base";
import { HubPage } from "pages/geoscape-hub/dashboard/dashboard-page";
import { Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router";
import * as Routes from "routes";
import { v4 } from "uuid";

const Account = lazy(() => import("pages/geoscape-admin/account/account-page"));
const AdminAccountDetails = lazy(
  () => import("pages/geoscape-admin/account/account-details/account-details")
);
const AdminRoutes = lazy(() => import("routes/admin-routes"));

export const RouteSwitch = () => {
  const location = useLocation();
  const errorKey = `${location.pathname}-${v4()}`;
  return (
    <ErrorBoundary key={errorKey} fallback={<ErrorFallback />}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={Routes.hub} component={HubPage} />

          {/* DEVELOPER */}
          <Route exact path={Routes.developerDashboard} component={Dashboard} />
          <Route
            exact
            path={Routes.developerApiKeysCreateKey}
            component={CreateKey}
          />
          <Route exact path={Routes.developerApiKeys} component={ApiKeysPage} />
          <Route exact path={Routes.developerUsage} component={Usage} />
          <Route
            exact
            path={Routes.developerApiKeysEditKey}
            component={EditApiKey}
          />
          {/* DATA */}

          <Route exact path={Routes.dataDashboard} component={DataPage} />
          <Route exact path={Routes.dataCatalogue} component={CataloguePage} />

          <Route exact path={Routes.demosPage} component={DemosPage} />
          <Route
            exact
            path={Routes.dataCatalogueEntry}
            component={CatalogueEntry}
          />
          <Route exact path={Routes.dataExplorer} component={ExplorerPage} />

          {import.meta.env.VITE_ENVIRONMENT !== "production" && (
            <Route exact path={Routes.demoBase} component={DemoBasePage} />
          )}

          <Route
            exact
            path={Routes.dataCustomDownload}
            component={CustomDownloadPage}
          />

          <Route exact path={Routes.dataTailor} component={TailorPage} />
          <Route exact path={Routes.dataDownload} component={DownloadPage} />

          {/* Batches */}
          <Route exact path={Routes.batchBatches} component={BatchPage} />

          <Route
            exact
            path={Routes.batchCreateBatch}
            component={CreateBatchPage}
          />

          <Route
            exact
            path={Routes.userCreateOrganisation}
            component={Organisations}
          />
          <Route
            exact
            path={Routes.userPendingInvitations}
            component={UserInvitations}
          />
          <Route
            exact
            path={Routes.userSubscription}
            component={UserSubscription}
          />
          <Route exact path={Routes.userSecurity} component={UserSecurity} />
          <Route
            exact
            path={Routes.userManageSubscription}
            component={UserManageSubscription}
          />
          <Route exact path={Routes.userOverage} component={UserOnDemand} />
          {/* It is disabled until his implementation */}
          {/* <Route exact path={Routes.userReceipts} component={UserReceipts} /> */}
          <Route
            exact
            path={Routes.userBillingDetails}
            component={UserBillingDetails}
          />
          <Route exact path={Routes.legal} component={LegalPage} />

          {/* Secret endpoint for testing error handling */}
          <Route exact path={Routes.testError} component={TestError} />

          {/*  Org Routes */}
          <OrgRoutes path={Routes.orgGeneralSettings}>
            <GeneralSettings />
          </OrgRoutes>
          <OrgRoutes path={Routes.orgMembers}>
            <OrganisationMembers />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgSubscription}>
            <OrgSubscription />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgOverage}>
            <OrgOnDemand />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgBillingDetails}>
            <OrgBillingDetails />
          </OrgRoutes>

          <OrgRoutes path={Routes.orgManageSubscription}>
            <OrganisationManageSubscription />
          </OrgRoutes>

          {/* <OrgRoutes
          exact
          path={Routes.userManageSubscription}
          component={ManageSubscription}
        /> */}

          {/* Admin Routes */}
          <AdminRoutes exact path={Routes.adminDashboard}>
            <AdminDashboardPage />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.propertyInsights}>
            <PropertyInsights />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminAccount}>
            <Account />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminReleases}>
            <MangeReleasePage />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminCreateRelease}>
            <NewRelease />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminEditRelease}>
            <Route
              exact
              path={Routes.adminEditRelease}
              component={NewRelease}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminReleaseDatasets}>
            <Route
              exact
              path={Routes.adminReleaseDatasets}
              component={ViewAllDatasets}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminDatasets}>
            <AdminDatasets />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminClipReporting}>
            <AdminClipReporting />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminViewCustomDatasets}>
            <AdminCustomDatasetsSearch />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminAccountDetails}>
            {/* <AdminAccountDetails/>  This approach does not recive the path params */}
            <Route
              exact
              path={Routes.adminAccountDetails}
              component={AdminAccountDetails}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminOrgMembers}>
            <Route
              exact
              path={Routes.adminOrgMembers}
              component={AdminMembers}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminUserMemberships}>
            <Route
              exact
              path={Routes.adminUserMemberships}
              component={AdminUserMemberships}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminAccountSubscription}>
            <Route
              exact
              path={Routes.adminAccountSubscription}
              component={AdminAccountSubscription}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminApiKeys}>
            <Route exact path={Routes.adminApiKeys} component={AdminApiKeys} />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminUsge}>
            <Route
              exact
              path={Routes.adminUsge}
              component={AdminUsageAccount}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminCreateApiKey}>
            <Route
              exact
              path={Routes.adminCreateApiKey}
              component={AdminCreateApiKey}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminEditApiKey}>
            <Route
              exact
              path={Routes.adminEditApiKey}
              component={AdminEditApiKey}
            />
          </AdminRoutes>

          {/* Custom Datasets */}
          <AdminRoutes exact path={Routes.adminViewCustomerCustomDatasets}>
            <Route
              exact
              path={Routes.adminViewCustomerCustomDatasets}
              component={ViewCustomDatasets}
            />
          </AdminRoutes>

          <AdminRoutes exact path={Routes.adminCreateCustomerCustomDatasets}>
            <Route
              exact
              path={Routes.adminCreateCustomerCustomDatasets}
              component={CreateCustomDatasets}
            />
          </AdminRoutes>

          <Route path="*">
            <Redirect to={Routes.hub} />
          </Route>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

const OrgRoutes = ({ children, ...rest }: any) => {
  const [identity] = useIdentity();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        getActiveOrganisation(identity) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
