import { Edit, Save, UploadFile } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Card,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import {
  ReactQueryQueryError,
  fetchCustomDatasetsDefinitions,
} from "data/queries";
import { UseQueryResult, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { FileWithPath } from "react-dropzone";
import { formatBytes } from "utils/commons";
import { HistoryState } from "../account/account-subscription/types";

import {
  DataGridPremium,
  GridApi,
  GridColDef,
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowId,
  GridRowModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { CustomDatasetsPost } from "data/models";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { FileUploadProgress } from "../../../components/upload/FileUploadProgress";
import { useCustomDatasetRows } from "./custom-hook/customDatasetRows";
import {
  useS3FileUploadDropper,
  useSubmitCustomDataset,
} from "./custom-hook/customHooks";

const styleWindowWidth = {
  xs: "100%",
  sm: "100%",
  md: "100%",
  lg: "100%",
  xl: "1600px",
};

const validateData = (rows: CustomDatasetsPost[]) => {
  const errors: string[] = [];
  const uniqueKeys: string[] = [];
  rows.forEach((row: CustomDatasetsPost) => {
    const dateValid = dayjs(row.release, "YYYY-MM", true).isValid();
    if (!dateValid) {
      errors.push(`${row.fileName}: Dates must be in YYYY-MM format`);
    }

    if (!row.name) {
      errors.push(`${row.fileName}: Dataset name cannot be blank`);
    }
    if (!row.region) {
      errors.push(`${row.fileName}: Dataset region cannot be blank`);
    }
    if (!row.datum) {
      errors.push(`${row.fileName}: Dataset datum cannot be blank`);
    }
    if (!row.format) {
      errors.push(`${row.fileName}: Dataset format cannot be blank`);
    }

    const key = `${row.name}|${row.region}|${row.format}|${row.datum}|${row.release}`;
    if (uniqueKeys.includes(key)) {
      errors.push(`${row.fileName}: Custom dataset fields must be unique`);
    } else {
      uniqueKeys.push(key);
    }
  });
  return errors;
};

const CreateCustomDatasets = () => {
  const history = useHistory();
  const {
    accountId,
    accountName,
    apigeeDeveloperId,
    salesforceId,
    salesforceIdType,
    email,
    hasCustomDataOp,
  } = history.location.state as HistoryState;

  const [identityState] = useIdentity();

  const customDatasetsDefinitionsQuery = useQuery(
    [QueryKeys.customDatasetsDefinitions, accountId],
    () =>
      fetchCustomDatasetsDefinitions(apigeeDeveloperId as string, identityState)
  );

  const {
    customDatasetsCreateMutation,
    customDatasetsError,
    customDatasetsSuccessful,
  } = useSubmitCustomDataset();

  const isLoading: Boolean = customDatasetsDefinitionsQuery.isLoading;
  const isError: Boolean = customDatasetsDefinitionsQuery.isError;
  const isSuccess: Boolean = customDatasetsDefinitionsQuery.isSuccess;

  const [errors, setErrors] = useState<string[]>([]);

  const {
    uploadProgress,
    isUploading,
    haveUploaded,
    files,
    removeFile,
    getRootProps,
    getInputProps,
    uploadFiles,
  } = useS3FileUploadDropper();

  const { rows, addRows, editRow, removeRow } = useCustomDatasetRows(
    customDatasetsDefinitionsQuery
  );

  useEffect(() => {
    setErrors(validateData(rows as CustomDatasetsPost[]));
  }, [rows]);

  useEffect(() => {
    addRows(files);
  }, [haveUploaded]);

  useEffect(() => {
    customDatasetsSuccessful.map((x) => removeCustomDataset(x));
  }, [customDatasetsSuccessful]);

  const removeCustomDataset = (fileName: string) => {
    removeFile(fileName);
    removeRow(fileName);
    if (currentEditRow === fileName) {
      setCurrentEditRow("none");
    }
  };

  const filesSelected = files.map((file: FileWithPath, index: number) => {
    if (!file.path) {
      return;
    }
    return (
      <FileUploadProgress
        key={index}
        uploadProgress={uploadProgress}
        file={file}
        onRemove={removeCustomDataset}
        isUploading={isUploading}
        haveUploaded={haveUploaded}
      />
    );
  });

  const apiRef: React.MutableRefObject<GridApi> = useGridApiRef();
  const [currentEditRow, setCurrentEditRow] = useState<GridRowId | "none">(
    "none"
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "relative",
          top: "-70px",
          width: styleWindowWidth,
        }}
      ></Box>
      {customDatasetsSuccessful &&
        customDatasetsSuccessful.map((success) => (
          <Alert sx={{ width: styleWindowWidth, marginBottom: "16px" }}>
            Successfully created dataset for {success}
          </Alert>
        ))}

      {customDatasetsError.length > 0 &&
        customDatasetsError.map((error) => (
          <Alert
            sx={{ width: styleWindowWidth, marginBottom: "16px" }}
            color="error"
          >
            {error.id} - {error.message}
          </Alert>
        ))}

      {errors.length > 0 && (
        <>
          {errors.map((e) => (
            <Alert severity="error">{e}</Alert>
          ))}
        </>
      )}

      <ReactQueryErrorWrapper
        queries={[]}
        mutations={[customDatasetsCreateMutation]}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: styleWindowWidth,
        }}
      >
        <Card style={{ width: "100%", padding: "16px", marginRight: "16px" }}>
          <Typography variant="h5">Source</Typography>
          <Typography variant="body2">
            Upload custom datasets for {accountName}
          </Typography>

          {!isUploading && !haveUploaded && (
            <Box
              style={{
                border: "dotted",
                borderColor: "grey",
                margin: "8px",
                padding: "8px",
                textAlign: "center",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input disabled={true} {...getInputProps()} />
              <UploadFile color="info" />
              <Typography>Click to upload or drag and drop files</Typography>
              <Typography variant="body2">*Max file size is 50 GB</Typography>
              <Typography></Typography>
            </Box>
          )}

          {filesSelected.length > 0 && (
            <>
              <Box>{filesSelected}</Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px",
                }}
              >
                <Typography color="orange">
                  *If you navigate away at any time, you will have to reupload
                  these files.
                </Typography>
                <LoadingButton
                  size="small"
                  loading={isUploading}
                  disabled={haveUploaded}
                  onClick={() =>
                    uploadFiles(import.meta.env.VITE_CD_UPLOAD_BUCKET_NAME)
                  }
                >
                  Upload Datasets
                </LoadingButton>
              </Box>
            </>
          )}
        </Card>

        <Card style={{ width: "100%", padding: "16px", height: "100%" }}>
          <Typography variant="h5">Customer</Typography>
          <Typography variant="body2">Customer information</Typography>

          <Typography>
            <b>Name:</b> {accountName}
          </Typography>
          <Typography>
            <b>Email:</b> {email}
          </Typography>
          <Typography>
            <b>Apigee Developer Id:</b> {apigeeDeveloperId}
          </Typography>
          <Typography>
            <b>Salesforce Account Id:</b>{" "}
            {salesforceIdType === "account" ? salesforceId : "None"}
          </Typography>
          <Typography>
            <b>Has Custom Data Opp:</b> {hasCustomDataOp ? "True" : "False"}
          </Typography>
        </Card>
      </Box>

      {haveUploaded && (
        <Card
          sx={{
            marginTop: "16px",
            width: styleWindowWidth,
          }}
        >
          {/* These two states of loading/error are VERY unlikely to ever happen at this stage */}
          {isLoading && <Typography>Loading...</Typography>}
          {isError && (
            <Typography color="red">
              Error -{" "}
              {
                (customDatasetsDefinitionsQuery.error as ReactQueryQueryError)
                  .message
              }
            </Typography>
          )}

          {isSuccess && (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="h5">Publisher</Typography>

                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <LoadingButton
                    disabled={currentEditRow !== "none" || errors.length > 0}
                    variant="contained"
                    onClick={() => {
                      customDatasetsCreateMutation.mutate({
                        values: rows as CustomDatasetsPost[],
                        publish: false,
                      });
                    }}
                    loading={customDatasetsCreateMutation.isLoading}
                  >
                    Create
                  </LoadingButton>
                  <LoadingButton
                    disabled={currentEditRow !== "none" || errors.length > 0}
                    variant="contained"
                    onClick={() =>
                      customDatasetsCreateMutation.mutate({
                        values: rows as CustomDatasetsPost[],
                        publish: true,
                      })
                    }
                    loading={customDatasetsCreateMutation.isLoading}
                  >
                    Publish All
                  </LoadingButton>
                </Box>
              </Box>

              <EditDataGrid
                customDatasetsDefinitionsQuery={customDatasetsDefinitionsQuery}
                rows={rows}
                editRow={editRow}
                currentEditRow={currentEditRow}
                setCurrentEditRow={setCurrentEditRow}
                setErrors={setErrors}
                apiRef={apiRef}
              />
            </Box>
          )}
        </Card>
      )}
    </Box>
  );
};

interface EditDataGridProps {
  customDatasetsDefinitionsQuery: UseQueryResult<any, unknown>;
  rows: GridRowModel[];
  editRow: (id: string, field: string, value: string) => void;
  currentEditRow: GridRowId | "none";
  setCurrentEditRow: React.Dispatch<React.SetStateAction<GridRowId | "none">>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  apiRef: React.MutableRefObject<GridApi>;
}

const EditDataGrid = (props: EditDataGridProps) => {
  dayjs.extend(customParseFormat);

  const autoCompleteComponent = (
    id: string,
    options: string[],
    params: any
  ) => {
    const changeFunc = (
      event: React.SyntheticEvent<Element, Event>,
      value: string
    ) => {
      props.editRow(params.id, params.field, value);
    };

    return (
      <Autocomplete
        {...params}
        sx={{
          width: "100%",
          display: "flex",

          height: "100%",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}
        id={id}
        size="small"
        options={options}
        onInputChange={changeFunc}
        onChange={changeFunc}
        renderInput={(riParams) => (
          <TextField
            sx={{ width: "100%" }}
            {...riParams}
            InputProps={{
              ref: riParams.InputProps.ref,
              className: riParams.InputProps.className,
              type: "search",
            }}
          />
        )}
        autoHighlight={false}
        freeSolo
        disableClearable
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Tooltip title={params.value}>
            <Typography variant="body1" noWrap>
              {params.value}
            </Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Dataset Name",
      width: 200,
      editable: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Typography>{params.value}</Typography>
          </Box>
        </Tooltip>
      ),
      renderEditCell: (params) =>
        autoCompleteComponent(
          "acName",
          props.customDatasetsDefinitionsQuery.data.names,
          params
        ),
    },
    {
      field: "region",
      headerName: "Region",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>{params.value}</Typography>
        </Box>
      ),
      renderEditCell: (params) =>
        autoCompleteComponent(
          "acRegion",
          props.customDatasetsDefinitionsQuery.data.regions,
          params
        ),
    },
    {
      field: "format",
      headerName: "Format",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>{params.value}</Typography>
        </Box>
      ),
      renderEditCell: (params) =>
        autoCompleteComponent(
          "acFormat",
          props.customDatasetsDefinitionsQuery.data.formats,
          params
        ),
    },
    {
      field: "datum",
      headerName: "Datum",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>{params.value}</Typography>
        </Box>
      ),
      renderEditCell: (params) =>
        autoCompleteComponent(
          "acDatum",
          props.customDatasetsDefinitionsQuery.data.datums,
          params
        ),
    },
    {
      field: "release",
      headerName: "Release Date",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>{params.value}</Typography>
        </Box>
      ),
      renderEditCell: (params) =>
        autoCompleteComponent(
          "acRelease",
          props.customDatasetsDefinitionsQuery.data.releases,
          params
        ),
    },
    {
      field: "version",
      headerName: "Version",
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <TextField
            defaultValue={
              (
                props.rows.filter(
                  (value: any) => value.id === params.id
                )[0] as any
              ).version
            }
            onChange={(event: any) => {
              props.editRow(
                params.id as string,
                params.field,
                event.target.value
              );
            }}
          />
        </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>
            {
              (
                props.rows.filter(
                  (value: any) => value.id === params.id
                )[0] as any
              ).version
            }
          </Typography>
        </Box>
      ),
    },
    {
      field: "fileSize",
      headerName: "File Size",
      width: 110,
      renderCell: (params) => (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Typography>{formatBytes(params.value)}</Typography>
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        const currentlyEditing = props.currentEditRow !== "none";
        const editingThisRow = props.currentEditRow === params.id;
        const editingOtherRow = currentlyEditing && !editingThisRow;
        return (
          <>
            {(!currentlyEditing || !editingThisRow) && (
              <IconButton disabled={editingOtherRow}>
                <Edit
                  onClick={(e) => {
                    props.setCurrentEditRow(params.id);
                    props.apiRef.current.startRowEditMode({ id: params.id });
                  }}
                  sx={{
                    color: editingOtherRow ? "grey" : "primary",
                  }}
                />
              </IconButton>
            )}
            {props.currentEditRow === params.id && (
              <IconButton>
                <Save
                  onClick={(e) => {
                    props.setCurrentEditRow("none");
                    props.apiRef.current.stopRowEditMode({ id: params.id });
                    props.setErrors(
                      validateData(props.rows as CustomDatasetsPost[])
                    );
                  }}
                />
              </IconButton>
            )}

            {/* <IconButton
            onClick={(event) => {
              props.setRows(props.rows.filter((row: GridRowModel) => row.id !== params.id));
            }}
          >
            <Delete color="error" />
          </IconButton> */}
          </>
        );
      },
    },
  ];

  return (
    <DataGridPremium
      apiRef={props.apiRef}
      rows={props.rows}
      pageSizeOptions={[10, 25, 50, 100]}
      sx={{ height: "631px" }}
      columns={columns}
      editMode="row"
      processRowUpdate={(updatedRow, originalRow) => {
        const stateRow = props.rows.find((x) => x.id === originalRow.id);
        const updatedValues: GridRowModel = {
          ...stateRow,
          version: updatedRow.version,
        };
        return updatedValues;
      }}
      onRowEditStart={(params: GridRowEditStartParams, event: MuiEvent) => {
        event.defaultMuiPrevented = true;
      }}
      onRowEditStop={(params: GridRowEditStopParams, event: MuiEvent) => {
        event.defaultMuiPrevented = true;
      }}
    />
  );
};

export { CreateCustomDatasets };
