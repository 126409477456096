import { Alert, Card, CardHeader, Skeleton, Stack } from "@mui/material";
import * as QueryKeys from "data";
import { ReactQueryMutationError } from "data/mutations";
import { fetchAdminClips } from "data/queries";
import { ClipConfiguration } from "pages/geoscape-data/explorer/clip/models";
import { useState } from "react";
import { useQuery } from "react-query";
import { AdminClipDetails } from "./admin-clip-details";
import { AdminClipMap } from "./admin-clip-map";
import { AdminClipSearch } from "./admin-clip-search";

export const AdminClipReporting = () => {
  const [selectedClip, setSelectedClip] = useState<ClipConfiguration>();
  const adminClipsQuery = useQuery([QueryKeys.adminClipsKey], () =>
    fetchAdminClips()
  );
  const cards = [
    "Total Clips",
    "Failed",
    "Completed",
    "Processing",
    "Quoted",
    "Quoting",
    "Draft",
  ];

  return (
    <>
      {adminClipsQuery.isError && (
        <Alert variant="outlined" severity="error">
          {(adminClipsQuery.error as ReactQueryMutationError).message}
        </Alert>
      )}
      {adminClipsQuery.isLoading && (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            width={1268}
            justifyContent="space-between"
          >
            {cards.map((value) => (
              <Skeleton
                key={value}
                variant="rectangular"
                width={162}
                height={84}
              />
            ))}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width={412} height={756} />
            <Skeleton variant="rectangular" width={412} height={756} />
            <Skeleton variant="rectangular" width={412} height={756} />
          </Stack>
        </Stack>
      )}
      {adminClipsQuery.isSuccess && (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="row"
            spacing={2}
            width={1268}
            justifyContent="space-between"
          >
            {cards.map((value) => (
              <Card
                sx={{
                  width: 162,
                  height: 84,
                }}
                key={value}
              >
                <CardHeader
                  title={
                    value === "Total Clips"
                      ? adminClipsQuery.data.length
                      : adminClipsQuery.data
                          .filter((item) => item.status === value)
                          .length.toLocaleString("en-AU", {
                            maximumFractionDigits: 1,
                          })
                  }
                  subheader={value}
                ></CardHeader>
              </Card>
            ))}
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdminClipSearch
              clips={adminClipsQuery.data}
              selectedClip={selectedClip}
              setSelectedClip={setSelectedClip}
            />
            {selectedClip && <AdminClipDetails clip={selectedClip} />}
            {selectedClip && selectedClip.geometry && (
              <AdminClipMap clip={selectedClip} />
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
};
